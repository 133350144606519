:root {
  --black: #1a1a1c;
  --blue: #37a4e2;
  --blue-dark: #2996d4;
  --blue-light: #92d1f6;
  --button-l-font-family: "Syncopate", Helvetica;
  --button-l-font-size: 18px;
  --button-l-font-style: normal;
  --button-l-font-weight: 700;
  --button-l-letter-spacing: -1.8px;
  --button-l-line-height: 120%;
  --button-m-font-family: "Syncopate", Helvetica;
  --button-m-font-size: 16px;
  --button-m-font-style: normal;
  --button-m-font-weight: 700;
  --button-m-letter-spacing: -1.6px;
  --button-m-line-height: 120%;
  --button-s-font-family: "Syncopate", Helvetica;
  --button-s-font-size: 13px;
  --button-s-font-style: normal;
  --button-s-font-weight: 700;
  --button-s-letter-spacing: -1.3px;
  --button-s-line-height: 120%;
  --button-XL-font-family: "Syncopate", Helvetica;
  --button-XL-font-size: 22px;
  --button-XL-font-style: normal;
  --button-XL-font-weight: 700;
  --button-XL-letter-spacing: -2.2px;
  --button-XL-line-height: 120%;
  --dark-gray: #5f5f60;
  --gray: #a8a8a8;
  --green: #c5ef4b;
  --green-dark: #aede25;
  --lavender: #cac2fa;
  --light-gray: #eee;
  --lime: #d8f87b;
  --logo-l-font-family: "Syncopate", Helvetica;
  --logo-l-font-size: 10px;
  --logo-l-font-style: normal;
  --logo-l-font-weight: 700;
  --logo-l-letter-spacing: -1px;
  --logo-l-line-height: 120%;
  --orange: #f68a3c;
  --red: #e73a3a;
  --red-dark: #cc3232;
  --rose: #f3b4b4;
  --rose-dark: #f29595;
  --rose-light: #fadada;
  --spacings-spacing-between-blocks-mobile: 80px;
  --spacings-spacing-betweenblock-desktop: 200px;
  --spacings-spacing-lg: 24px;
  --spacings-spacing-md: 16px;
  --spacings-spacing-none: 0px;
  --spacings-spacing-sm: 8px;
  --spacings-spacing-xl: 32px;
  --spacings-spacing-xs: 4px;
  --spacings-spacing-xxl: 40px;
  --spacings-spacing-xxs: 2px;
  --spacings-spacing-xxxl: 48px;
  --swampy: #a6c747;
  --violet: #7a67f2;
  --violet-dark: #6957da;
  --violet-light: #7f6eed;
  --white: #fff;
  --XL-body-BL-bold-font-family: "Red Hat Display", Helvetica;
  --XL-body-BL-bold-font-size: 28px;
  --XL-body-BL-bold-font-style: normal;
  --XL-body-BL-bold-font-weight: 700;
  --XL-body-BL-bold-letter-spacing: 0px;
  --XL-body-BL-bold-line-height: 130.0%;
  --XL-body-BL-semibold-font-family: "Red Hat Display", Helvetica;
  --XL-body-BL-semibold-font-size: 28px;
  --XL-body-BL-semibold-font-style: normal;
  --XL-body-BL-semibold-font-weight: 600;
  --XL-body-BL-semibold-letter-spacing: 0px;
  --XL-body-BL-semibold-line-height: 130.0%;
  --XL-body-BM-bold-font-family: "Red Hat Display", Helvetica;
  --XL-body-BM-bold-font-size: 26px;
  --XL-body-BM-bold-font-style: normal;
  --XL-body-BM-bold-font-weight: 700;
  --XL-body-BM-bold-letter-spacing: 0px;
  --XL-body-BM-bold-line-height: 120%;
  --XL-body-BM-reg-font-family: "Red Hat Display", Helvetica;
  --XL-body-BM-reg-font-size: 26px;
  --XL-body-BM-reg-font-style: normal;
  --XL-body-BM-reg-font-weight: 400;
  --XL-body-BM-reg-letter-spacing: 0px;
  --XL-body-BM-reg-line-height: 130.0%;
  --XL-body-BM-semibold-font-family: "Red Hat Display", Helvetica;
  --XL-body-BM-semibold-font-size: 26px;
  --XL-body-BM-semibold-font-style: normal;
  --XL-body-BM-semibold-font-weight: 600;
  --XL-body-BM-semibold-letter-spacing: 0px;
  --XL-body-BM-semibold-line-height: 130.0%;
  --XL-body-BS-semibold-font-family: "Red Hat Display", Helvetica;
  --XL-body-BS-semibold-font-size: 24px;
  --XL-body-BS-semibold-font-style: normal;
  --XL-body-BS-semibold-font-weight: 600;
  --XL-body-BS-semibold-letter-spacing: 0px;
  --XL-body-BS-semibold-line-height: 140.0%;
  --XL-body-BXS-reg-font-family: "Red Hat Display", Helvetica;
  --XL-body-BXS-reg-font-size: 22px;
  --XL-body-BXS-reg-font-style: normal;
  --XL-body-BXS-reg-font-weight: 400;
  --XL-body-BXS-reg-letter-spacing: 0px;
  --XL-body-BXS-reg-line-height: 140.0%;
  --XL-caps-CS-bold-font-family: "Red Hat Display", Helvetica;
  --XL-caps-CS-bold-font-size: 18px;
  --XL-caps-CS-bold-font-style: normal;
  --XL-caps-CS-bold-font-weight: 700;
  --XL-caps-CS-bold-letter-spacing: 0px;
  --XL-caps-CS-bold-line-height: 120%;
  --XL-caps-CXS-med-font-family: "Red Hat Display", Helvetica;
  --XL-caps-CXS-med-font-size: 16px;
  --XL-caps-CXS-med-font-style: normal;
  --XL-caps-CXS-med-font-weight: 500;
  --XL-caps-CXS-med-letter-spacing: 0px;
  --XL-caps-CXS-med-line-height: 130.0%;
  --XL-headline-HM-font-family: "Syncopate", Helvetica;
  --XL-headline-HM-font-size: 50px;
  --XL-headline-HM-font-style: normal;
  --XL-headline-HM-font-weight: 700;
  --XL-headline-HM-letter-spacing: -5px;
  --XL-headline-HM-line-height: 130.0%;
  --XL-headline-HS-font-family: "Syncopate", Helvetica;
  --XL-headline-HS-font-size: 28px;
  --XL-headline-HS-font-style: normal;
  --XL-headline-HS-font-weight: 700;
  --XL-headline-HS-letter-spacing: -2.8px;
  --XL-headline-HS-line-height: 120%;
  --XL-small-text-ST-l-bold-font-family: "Red Hat Display", Helvetica;
  --XL-small-text-ST-l-bold-font-size: 18px;
  --XL-small-text-ST-l-bold-font-style: normal;
  --XL-small-text-ST-l-bold-font-weight: 700;
  --XL-small-text-ST-l-bold-letter-spacing: 0px;
  --XL-small-text-ST-l-bold-line-height: 130.0%;
  --XL-small-text-ST-l-med-font-family: "Red Hat Display", Helvetica;
  --XL-small-text-ST-l-med-font-size: 18px;
  --XL-small-text-ST-l-med-font-style: normal;
  --XL-small-text-ST-l-med-font-weight: 500;
  --XL-small-text-ST-l-med-letter-spacing: 0px;
  --XL-small-text-ST-l-med-line-height: 130.0%;
  --XL-small-text-ST-l-reg-font-family: "Red Hat Display", Helvetica;
  --XL-small-text-ST-l-reg-font-size: 18px;
  --XL-small-text-ST-l-reg-font-style: normal;
  --XL-small-text-ST-l-reg-font-weight: 400;
  --XL-small-text-ST-l-reg-letter-spacing: 0px;
  --XL-small-text-ST-l-reg-line-height: 130.0%;
  --XL-small-text-ST-s-semibold-font-family: "Red Hat Display", Helvetica;
  --XL-small-text-ST-s-semibold-font-size: 16px;
  --XL-small-text-ST-s-semibold-font-style: normal;
  --XL-small-text-ST-s-semibold-font-weight: 600;
  --XL-small-text-ST-s-semibold-letter-spacing: 0px;
  --XL-small-text-ST-s-semibold-line-height: 140.0%;
  --XL-title-TL-font-family: "Syncopate", Helvetica;
  --XL-title-TL-font-size: 26px;
  --XL-title-TL-font-style: normal;
  --XL-title-TL-font-weight: 700;
  --XL-title-TL-letter-spacing: -2.6px;
  --XL-title-TL-line-height: 140.0%;
  --XL-title-TM-font-family: "Red Hat Display", Helvetica;
  --XL-title-TM-font-size: 26px;
  --XL-title-TM-font-style: normal;
  --XL-title-TM-font-weight: 700;
  --XL-title-TM-letter-spacing: 0px;
  --XL-title-TM-line-height: 120%;
  --XL-title-TS-font-family: "Syncopate", Helvetica;
  --XL-title-TS-font-size: 24px;
  --XL-title-TS-font-style: normal;
  --XL-title-TS-font-weight: 700;
  --XL-title-TS-letter-spacing: -2.4px;
  --XL-title-TS-line-height: 120%;
  --XL-title-TXS-font-family: "Syncopate", Helvetica;
  --XL-title-TXS-font-size: 18px;
  --XL-title-TXS-font-style: normal;
  --XL-title-TXS-font-weight: 700;
  --XL-title-TXS-letter-spacing: -1.8px;
  --XL-title-TXS-line-height: 130.0%;
  --XS-body-BL-bold-font-family: "Red Hat Display", Helvetica;
  --XS-body-BL-bold-font-size: 16px;
  --XS-body-BL-bold-font-style: normal;
  --XS-body-BL-bold-font-weight: 700;
  --XS-body-BL-bold-letter-spacing: 0px;
  --XS-body-BL-bold-line-height: 130.0%;
  --XS-body-BL-reg-font-family: "Red Hat Display", Helvetica;
  --XS-body-BL-reg-font-size: 16px;
  --XS-body-BL-reg-font-style: normal;
  --XS-body-BL-reg-font-weight: 400;
  --XS-body-BL-reg-letter-spacing: 0px;
  --XS-body-BL-reg-line-height: 140.0%;
  --XS-body-BL-semibold-font-family: "Red Hat Display", Helvetica;
  --XS-body-BL-semibold-font-size: 16px;
  --XS-body-BL-semibold-font-style: normal;
  --XS-body-BL-semibold-font-weight: 600;
  --XS-body-BL-semibold-letter-spacing: 0px;
  --XS-body-BL-semibold-line-height: 140.0%;
  --XS-body-BM-bold-font-family: "Red Hat Display", Helvetica;
  --XS-body-BM-bold-font-size: 14px;
  --XS-body-BM-bold-font-style: normal;
  --XS-body-BM-bold-font-weight: 700;
  --XS-body-BM-bold-letter-spacing: 0px;
  --XS-body-BM-bold-line-height: 130.0%;
  --XS-body-BM-reg-font-family: "Red Hat Display", Helvetica;
  --XS-body-BM-reg-font-size: 14px;
  --XS-body-BM-reg-font-style: normal;
  --XS-body-BM-reg-font-weight: 400;
  --XS-body-BM-reg-letter-spacing: 0px;
  --XS-body-BM-reg-line-height: 130.0%;
  --XS-body-BM-semibold-font-family: "Red Hat Display", Helvetica;
  --XS-body-BM-semibold-font-size: 14px;
  --XS-body-BM-semibold-font-style: normal;
  --XS-body-BM-semibold-font-weight: 600;
  --XS-body-BM-semibold-letter-spacing: 0px;
  --XS-body-BM-semibold-line-height: 130.0%;
  --XS-caps-CM-bold-font-family: "Red Hat Display", Helvetica;
  --XS-caps-CM-bold-font-size: 14px;
  --XS-caps-CM-bold-font-style: normal;
  --XS-caps-CM-bold-font-weight: 700;
  --XS-caps-CM-bold-letter-spacing: 0px;
  --XS-caps-CM-bold-line-height: 120%;
  --XS-caps-CXS-semibold-font-family: "Red Hat Display", Helvetica;
  --XS-caps-CXS-semibold-font-size: 12px;
  --XS-caps-CXS-semibold-font-style: normal;
  --XS-caps-CXS-semibold-font-weight: 600;
  --XS-caps-CXS-semibold-letter-spacing: 0px;
  --XS-caps-CXS-semibold-line-height: 130.0%;
  --XS-headline-HL-font-family: "Syncopate", Helvetica;
  --XS-headline-HL-font-size: 21px;
  --XS-headline-HL-font-style: normal;
  --XS-headline-HL-font-weight: 700;
  --XS-headline-HL-letter-spacing: -2.1px;
  --XS-headline-HL-line-height: 130.0%;
  --XS-headline-HM-font-family: "Syncopate", Helvetica;
  --XS-headline-HM-font-size: 20px;
  --XS-headline-HM-font-style: normal;
  --XS-headline-HM-font-weight: 700;
  --XS-headline-HM-letter-spacing: -2px;
  --XS-headline-HM-line-height: 130.0%;
  --XS-headline-HS-font-family: "Syncopate", Helvetica;
  --XS-headline-HS-font-size: 16px;
  --XS-headline-HS-font-style: normal;
  --XS-headline-HS-font-weight: 700;
  --XS-headline-HS-letter-spacing: -1.6px;
  --XS-headline-HS-line-height: 120%;
  --XS-small-text-ST-l-bold-font-family: "Red Hat Display", Helvetica;
  --XS-small-text-ST-l-bold-font-size: 12px;
  --XS-small-text-ST-l-bold-font-style: normal;
  --XS-small-text-ST-l-bold-font-weight: 700;
  --XS-small-text-ST-l-bold-letter-spacing: 0px;
  --XS-small-text-ST-l-bold-line-height: 130.0%;
  --XS-small-text-ST-l-medium-font-family: "Red Hat Display", Helvetica;
  --XS-small-text-ST-l-medium-font-size: 12px;
  --XS-small-text-ST-l-medium-font-style: normal;
  --XS-small-text-ST-l-medium-font-weight: 500;
  --XS-small-text-ST-l-medium-letter-spacing: 0px;
  --XS-small-text-ST-l-medium-line-height: 130.0%;
  --XS-small-text-ST-l-reg-font-family: "Red Hat Display", Helvetica;
  --XS-small-text-ST-l-reg-font-size: 12px;
  --XS-small-text-ST-l-reg-font-style: normal;
  --XS-small-text-ST-l-reg-font-weight: 400;
  --XS-small-text-ST-l-reg-letter-spacing: 0px;
  --XS-small-text-ST-l-reg-line-height: 130.0%;
  --XS-title-TL-font-family: "Syncopate", Helvetica;
  --XS-title-TL-font-size: 14px;
  --XS-title-TL-font-style: normal;
  --XS-title-TL-font-weight: 700;
  --XS-title-TL-letter-spacing: -1.4px;
  --XS-title-TL-line-height: 120%;
  --XS-title-TM-font-family: "Syncopate", Helvetica;
  --XS-title-TM-font-size: 12px;
  --XS-title-TM-font-style: normal;
  --XS-title-TM-font-weight: 700;
  --XS-title-TM-letter-spacing: -1.2px;
  --XS-title-TM-line-height: 120%;
}
/*# sourceMappingURL=index.5a010a82.css.map */
